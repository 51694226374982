import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import providerStoreModule from '../providerStoreModule';

export default function useProviderList() {
  const STORE_MODULE_NAME = 'provider';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, providerStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();
  // add / edit
  const blankItem = {
    id: 0,
    name: '',
    phone: '',
    contactName: '',
    address: '',
    url: '',
    note: '',
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'provider') && !vm.$can('delete', 'provider');

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
    },
    {
      label: t('Tên'),
      field: 'name',
    },
    {
      label: t('Số điện thoại'),
      field: 'phone',
    },
    {
      label: t('Người liên hệ'),
      field: 'contactName',
    },
    {
      label: t('Địa chỉ'),
      field: 'address',
    },
    {
      label: t('Liên kết'),
      field: 'url',
    },
    {
      label: t('Ghi chú'),
      field: 'note',
    },
    {
      label: t('Thao tác'),
      field: 'action',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  const fetchProviders = () => {
    store
      .dispatch('provider/fetchProviders', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteProviders = providers => {
    store
      .dispatch('provider/deleteProviders', {
        ids: providers.map(_obj => _obj.id),
      })
      .then(() => {
        fetchProviders();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createProvider = () => {
    store
      .dispatch('provider/createProvider', {
        name: item.value.name,
      })
      .then(() => {
        fetchProviders();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchProviders();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  const onDelete = provider => {
    const deleteObjects = provider && provider.id > 0 ? [provider] : selectedRows.value;
    deleteProviders(deleteObjects);
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  //   API Call

  const refetchProviders = () => {
    fetchProviders();
  };

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchProviders,
    fetchProviders,
    deleteProviders,
    createProvider,
    t,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    onDelete,
  };
}
